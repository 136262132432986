import React from 'react';
import cn from 'classnames';
import { compareDesc } from 'date-fns';
import { currency } from '@utils/numbers';
import { RubleSign } from '@components/Blocks/RubleSign';
import { Paragraph as P, Heading as H } from '@periodica/ui-kit';

import * as styles from './GiftBonusBlock.module.css';

const BONUS_DATE_START = new Date(2024, 11, 28);
const BONUS_DATE_END = new Date(2025, 0, 10); // +1 день, если по 9 января включительно

interface GiftBonusBlockProps {
  sum: number;
  toPay: boolean;
}

export const getBonusPercent = (sum: number) => {
  const compareStart = compareDesc(BONUS_DATE_START, new Date());
  const compareEnd = compareDesc(new Date(), BONUS_DATE_END);

  if (compareStart + compareEnd === 2) {
    if (sum >= 10000 && sum < 30000) {
      return 0.08;
    }
    if (sum >= 30000 && sum < 50000) {
      return 0.09;
    }
    if (sum >= 50000) {
      return 0.1;
    }
  }

  return 0;
};

export function GiftBonusBlock({ sum = 0, toPay = false }: GiftBonusBlockProps) {
  const bonus = Math.round(getBonusPercent(sum) * sum);

  return bonus ? (
    <div className={cn([styles.bonusBlock, !toPay && styles.withoutTopMargin])}>
      <div className={styles.bonusBlockContent}>
        <div className={cn([styles.bonusBlockItem, styles.sale])}>
          <P size="inherit">Вы получите бонус*</P>
          <P size="inherit">
            +&nbsp;{currency(bonus)}&nbsp;
            <RubleSign />
          </P>
        </div>

        {toPay ? (
          <>
            <div className={styles.bonusBlockItem}>
              <P size="inherit">Номинал сертификата</P>
              <P size="inherit">
                {currency(sum + bonus)}&nbsp;
                <RubleSign />
              </P>
            </div>
            <div className={cn([styles.bonusBlockItem, styles.emphasized])}>
              <H variant="h3" customTag="p">
                К оплате
              </H>
              <H variant="h3" customTag="p">
                {currency(sum)}&nbsp;
                <RubleSign />
              </H>
            </div>
          </>
        ) : (
          <div className={styles.bonusBlockItem}>
            <H variant="h3" customTag="p">
              Номинал сертификата
            </H>
            <H variant="h3" customTag="p">
              {currency(sum + bonus)}&nbsp;
              <RubleSign />
            </H>
          </div>
        )}
        <P size="small" color="secondary">
          Дарим дополнительные бонусы при&nbsp;покупке сертификатов! На&nbsp;сумму
          от&nbsp;10&nbsp;000&nbsp;₽&nbsp;— начисляем 8% от&nbsp;номинала,
          от&nbsp;30&nbsp;000&nbsp;₽&nbsp;— 9%, а&nbsp;от&nbsp;50&nbsp;000&nbsp;₽ и&nbsp;выше&nbsp;—
          10%. Чем&nbsp;больше сумма, тем&nbsp;больше бонусов!
        </P>
      </div>
    </div>
  ) : null;
}
