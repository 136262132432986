import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import Slider from 'react-slick';

import { removeEmoji } from '@periodica/ui-kit/remove-emoji';
import { NextArrow } from '@components/Gallery/NextArrow';
import { PrevArrow } from '@components/Gallery/PrevArrow';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './CertificatePatternField.module.scss';

const customPaging = () => <div className={styles.dot} />;

const appendDots = (dots) => (
  <div className={styles.navigation}>
    <ul className={styles.dots}>
      {dots.map((dot) => (
        <li
          key={dot.key}
          className={cn({
            [styles.dot]: true,
            [styles.dotActive]: dot.props.className === 'slick-active',
          })}
        >
          {dot.props.children}
        </li>
      ))}
    </ul>
  </div>
);

const sliderSettings = {
  customPaging,
  appendDots,
  centerMode: true,
  className: 'slider',
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  swipeToSlide: true,
  dotsClass: styles.navigation,
  speed: 500,
  centerPadding: '40px',
  responsive: [
    { breakpoint: 1280, settings: { variableWidth: true, centerMode: true } },
    { breakpoint: 768, settings: { variableWidth: true, centerMode: true } },
    { breakpoint: 395, settings: { variableWidth: true, centerMode: true } },
  ],
  nextArrow: <NextArrow externalClassName={styles.arrow} black />,
  prevArrow: <PrevArrow externalClassName={styles.arrow} black />,
  dots: true,
  infinite: true,
};

/* eslint-disable react/jsx-props-no-spreading */
const CertificatePatternField = React.forwardRef(function CertificatePatternField(
  { patterns, onTextChange, onSlideChange, value, label, error },
  ref
) {
  const onAfterChange = React.useCallback(
    (index) => {
      const pattern = patterns[index];
      onSlideChange(pattern);
    },
    [patterns, onSlideChange]
  );

  const areaOptions = {
    rows: 3,
    cols: 14,
    maxLength: 50,
  };

  const handleTextChange = React.useCallback(
    (e) => {
      onTextChange(e.target.value);
    },
    [onTextChange]
  );

  const handleTextKeyUp = React.useCallback((e) => {
    e.target.value = removeEmoji(e.target.value);
    let lines = e.target.value.split('\n');
    lines = lines.slice(0, 3);
    e.target.value = lines.join('\n');
  }, []);

  return (
    <div className={styles.banners}>
      <div className={styles.patterns}>
        <Slider {...sliderSettings} initialSlide={value?.slideId || 0} afterChange={onAfterChange}>
          {patterns.map((pattern) => (
            <div key={`pattern-${pattern.slideId}`}>
              <div
                className={styles.pattern}
                style={{
                  backgroundImage: `url(${pattern.image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '0 0',
                }}
              >
                {pattern.isTextEnabled && (
                  <textarea
                    id="text_area"
                    ref={ref}
                    {...areaOptions}
                    className={styles.textarea}
                    placeholder={label}
                    onChange={handleTextChange}
                    onKeyUp={handleTextKeyUp}
                    value={value?.text}
                  />
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {error && <div className={styles.errorLabel}>{error}</div>}
    </div>
  );
});
/* eslint-enable */

export default CertificatePatternField;

CertificatePatternField.defaultProps = {
  onTextChange: undefined,
  onSlideChange: undefined,
  value: {},
  label: 'Введите ваше пожелание здесь',
};

CertificatePatternField.propTypes = {
  patterns: propTypes.arrayOf(propTypes.shape({ id: propTypes.number, image: propTypes.string }))
    .isRequired,
  onTextChange: propTypes.func,
  onSlideChange: propTypes.func,
  value: propTypes.shape({ id: propTypes.number, text: propTypes.string }),
  label: propTypes.string,
};
