import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { navigate } from 'gatsby';
import propTypes from 'prop-types';

import {
  TextField,
  FormField,
  PhoneInput,
  SwitchButtons as DeliveryTypeField,
  Paragraph as P,
  Heading as H,
  CheckboxField,
  Button,
} from '@periodica/ui-kit';

import { Note } from '@components/Note';

import { DEFAULT_DESIGN_PRICE } from '@consts/designConsts';
import { event } from '@analytics';
import { getCertificatePrices } from '@utils/productsForPrice';
import { useProducts } from '@hooks/useProducts';
import { Switcher } from '@components/Blocks/Switcher';
import {
  DEFAULT_PRICE,
  TYPE_RECIPIENT_EMAIL,
  GIFT_OPTION_DEFAULT,
  GIFT_OPTION_CUSTOM,
  GIFT_SUM_DEFAULT,
  GIFT_SUM_CUSTOM,
  CUSTOM_CERT_ID_PROD,
  CUSTOM_CERT_ID_DEV,
} from '@consts/giftConsts';
import { CertificateImageUpload } from '@components/Forms/CertificateImageUpload';
import { generateCustomGiftSvg } from '@utils/generateCustomGiftSvg';
import iconMyEmailWhite from '@images/icons/print-gift-white.svg';
import iconMyEmailGray from '@images/icons/print-gift.svg';
import iconRecipientWhite from '@images/icons/web-gift-white.svg';
import iconRecipientGray from '@images/icons/web-gift.svg';

import SumSelector from '../SumSelector';
import CertificatePatternField from '../CertificatePatternField';
import { GiftBonusBlock } from './GiftBonusBlock';
import { getGiftCards } from '../../../periodicaApi/gift';

import styles from './GiftForm.module.scss';

const switchInfo = [
  {
    activePic: iconRecipientWhite,
    disablePic: iconRecipientGray,
    header: 'На E-mail получателя',
    description: 'Доставим сразу',
    value: TYPE_RECIPIENT_EMAIL,
  },
  {
    activePic: iconMyEmailWhite,
    disablePic: iconMyEmailGray,
    header: 'На мой E-mail',
    description: 'Распечатайте и вручите лично',
    value: 2,
  },
];

const isDevelopment = process.env.GATSBY_ACTIVE_ENV === 'development';

function FormBlockHeading({ children }) {
  return (
    <H variant="h2" className={styles.h3}>
      {children}
    </H>
  );
}

function GiftForm({ values }) {
  const [state, setState] = React.useState({
    text: '',
    sum: Number(values?.sum?.sum) || DEFAULT_PRICE,
    deliveryType: values?.deliveryType || TYPE_RECIPIENT_EMAIL,
    giftCards: [],
    giftPrices: [],
  });

  const [giftType, setGiftType] = React.useState(GIFT_OPTION_DEFAULT);
  const [giftSumType, setGiftSumType] = React.useState(GIFT_SUM_DEFAULT);
  const [savedSumObj, setSavedSumObj] = React.useState(values?.sum || null);
  const [savedCustomSum, setSavedCustomSum] = React.useState(values?.customSum || null);

  const hasError = (value) => {
    if (value.isTextEnabled === false || (value && value.text?.trim())) {
      return true;
    }

    return 'Введите пожелание';
  };

  const hasCustomImageError = (value) => {
    if (giftType === GIFT_OPTION_DEFAULT || (giftType !== GIFT_OPTION_DEFAULT && value)) {
      return true;
    }

    return 'Пожалуйста, загрузите изображение';
  };

  const validatePhoneNumber = (value) => {
    if (value.length === 18) {
      return true;
    }

    return 'Неверный формат номера телефона. Введите, пожалуйста, 11 цифр.';
  };

  const isPhotobook = (products) => products?.find((option) => option.productType === 'book');

  const { deliveryType, sum } = state;

  const { allProducts } = useProducts();
  const filteredProducts = allProducts.filter(
    (product) =>
      !['gift', 'wedding', 'detskaya', 'postcard', 'wrappingPaper'].includes(product.productType) &&
      !product.isUnavailable
  );

  const giftPrices = getCertificatePrices(filteredProducts);
  const defaultProduct = giftPrices.find((option) => option.sum === sum);

  const initialValues = {
    pattern: {
      image: values?.pattern?.image || state.giftCards[0]?.image,
      backImage: values?.pattern?.backImage || state.giftCards[0]?.backImage,
      frontLayout: values?.pattern?.frontLayout || state.giftCards[0]?.frontLayout,
      template_id: values?.pattern?.template_id || state.giftCards[0]?.template_id,
      slideId: values?.pattern?.slideId || state.giftCards[0]?.slideId,
      text: values?.pattern?.text || '',
      isTextEnabled: values?.pattern?.isTextEnabled || false,
    },
    sum: null,
    customSum: values?.customSum || null,
    isDesignOn: values?.isDesignOn || false,
    email: values?.email || '',
    emailTo: values?.emailTo || '',
    nameFrom: values?.nameFrom || '',
    nameTo: values?.nameTo || '',
    phone: values?.phone || '',
    deliveryType: TYPE_RECIPIENT_EMAIL,
    previewUrl: values?.previewUrl || null,
    client_newsletter_agreed: values?.client_newsletter_agreed,
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    mode: 'onSubmit',
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const watchSum = watch('sum');
  const watchIsDesignOn = watch('isDesignOn');
  const totalSum = watchIsDesignOn
    ? (savedSumObj?.sum || 0) + DEFAULT_DESIGN_PRICE
    : savedSumObj?.sum;

  const getInfo = async () => {
    const giftCards = await getGiftCards({});
    setState((prev) => ({
      ...prev,
      giftCards,
      giftPrices,
    }));
    return null;
  };

  const handleDeliveryTypeChange = (onChangeHandler) => (value) => {
    onChangeHandler(value);
    setState((prev) => ({ ...prev, deliveryType: value }));
  };

  const handleGiftSumTypeChange = (type) => {
    if (type === giftSumType) {
      return;
    }
    if (type === GIFT_SUM_DEFAULT) {
      setValue('sum', savedSumObj);
    } else {
      setValue('customSum', savedCustomSum);
    }

    if (!isPhotobook(savedSumObj?.products)) {
      setValue('isDesignOn', false);
    }
    event('event_site_gift_select-gift-sum-type');
    setGiftSumType(type);
  };

  const handleGiftTypeChange = (type) => {
    if (type === giftType) {
      return;
    }
    event('event_site_gift_select-gift-type');
    setGiftType(type);
  };

  const onSubmit = async (data) => {
    let pattern;
    if (giftType === GIFT_OPTION_DEFAULT) {
      pattern = data?.pattern?.image
        ? { ...data.pattern }
        : { ...state.giftCards[0], text: data.pattern.text };
    } else {
      pattern = {
        image: data.previewUrl,
        frontLayout: generateCustomGiftSvg(data?.previewUrl),
        template_id: isDevelopment ? CUSTOM_CERT_ID_DEV : CUSTOM_CERT_ID_PROD,
        slideId: state.giftCards[0]?.slideId,
        text: '',
        isTextEnabled: false,
      };
    }
    const patternText = pattern?.isTextEnabled === false ? '' : pattern.text;
    const normalizedPattern = {
      ...pattern,
      text: patternText,
    };

    event('event_site_gift_finish-order');
    navigate('/products/gift/confirmation/', {
      state: {
        ...data,
        deliveryType: state.deliveryType,
        pattern: normalizedPattern,
        giftType,
      },
    });
  };

  useEffect(() => {
    if (giftType === GIFT_OPTION_DEFAULT && values?.previewUrl) {
      setValue('pattern', {
        image: state.giftCards[0]?.image,
        backImage: state.giftCards[0]?.backImage,
        frontLayout: state.giftCards[0]?.frontLayout,
        template_id: state.giftCards[0]?.template_id,
        slideId: state.giftCards[0]?.slideId,
        text: '',
        isTextEnabled: false,
      });
    }
    // TODO: разобраться с зависимостями
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftType]);

  useEffect(() => {
    // need to correct render of giftType and price when state exists and page reloads
    setValue('sum', defaultProduct);
    setValue('deliveryType', values?.deliveryType || TYPE_RECIPIENT_EMAIL);
    setGiftType(values?.giftType || GIFT_OPTION_DEFAULT);
    setSavedSumObj(defaultProduct);
    setGiftSumType(values?.customSum ? GIFT_SUM_CUSTOM : GIFT_SUM_DEFAULT);
    // TODO: разобраться с зависимостями
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const switcherPictureOptions = [
    {
      id: GIFT_OPTION_DEFAULT,
      title: 'Все сертификаты',
    },
    {
      id: GIFT_OPTION_CUSTOM,
      title: 'Загрузить свое фото',
    },
  ];

  const switcherSumOptions = [
    {
      id: GIFT_SUM_DEFAULT,
      title: 'Выбрать из списка',
    },
    {
      id: GIFT_SUM_CUSTOM,
      title: 'Указать свой номинал',
    },
  ];

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} id="giftForm">
      <div className={styles.formWrapper}>
        <div className={styles.formBlock}>
          <FormBlockHeading>1. Укажите получателя</FormBlockHeading>
          <Controller
            render={({ field: { onChange, value, ref } }) => (
              <FormField
                label="Имя получателя"
                name="nameTo"
                error={errors && errors.nameTo && errors.nameTo.message}
              >
                <TextField
                  ref={ref}
                  name="nameTo"
                  onChange={(val) => onChange(val)}
                  onBlur={() => value && event('event_site_gift_enter-name-to')}
                  value={value}
                  error={errors && errors.nameTo && errors.nameTo.message}
                />
              </FormField>
            )}
            control={control}
            name="nameTo"
            rules={{
              required: 'Введите Имя получателя',
            }}
          />
        </div>
        <div className={styles.formBlock}>
          <FormBlockHeading>2. Выберите дизайн</FormBlockHeading>
          <div className={styles.switcher}>
            <Switcher
              activeOption={giftType}
              options={switcherPictureOptions}
              onSwitch={handleGiftTypeChange}
            />
          </div>
          <P size="medium" className={styles.sliderLabel}>{`Выберите фон${
            giftType === GIFT_OPTION_DEFAULT ? ' и добавьте пожелание' : ''
          }`}</P>
        </div>
      </div>
      {giftType === GIFT_OPTION_DEFAULT ? (
        <div className={styles.formSlider}>
          <Controller
            render={({ field: { onChange, value, ref } }) => (
              <CertificatePatternField
                ref={ref}
                onSlideChange={(pattern) => {
                  onChange({
                    ...value,
                    image: pattern?.image,
                    backImage: pattern?.backImage,
                    frontLayout: pattern?.frontLayout,
                    template_id: pattern?.template_id,
                    slideId: pattern?.slideId,
                    isTextEnabled: pattern?.isTextEnabled,
                  });
                }}
                onTextChange={(text) => onChange({ ...value, text })}
                value={value}
                patterns={state.giftCards}
                error={errors && errors.pattern && errors.pattern.message}
              />
            )}
            name="pattern"
            control={control}
            rules={{
              validate: (value) => hasError(value),
            }}
          />
        </div>
      ) : (
        <div className={styles.formWrapper}>
          <div className={styles.customGiftBlock}>
            <div className={styles.customPhotoContainer}>
              <Controller
                render={({ field: { onChange, value, ref } }) => (
                  <CertificateImageUpload
                    ref={ref}
                    onUploadPhotos={(file) => {
                      onChange(file);
                    }}
                    onDelete={() => onChange(null)}
                    uploadedFile={value}
                    error={errors && errors.previewUrl && errors.previewUrl.message}
                  />
                )}
                name="previewUrl"
                control={control}
                rules={{
                  validate: (value) => hasCustomImageError(value),
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles.formWrapper}>
        <div className={styles.formBlock}>
          <FormBlockHeading>3. Выберите номинал</FormBlockHeading>
          <div className={styles.switcher}>
            <Switcher
              activeOption={giftSumType}
              options={switcherSumOptions}
              onSwitch={handleGiftSumTypeChange}
            />
          </div>
          {giftSumType === GIFT_SUM_DEFAULT ? (
            <div className={styles.sumWrapper}>
              <Controller
                render={({ field: { onChange, value, ref } }) => (
                  <SumSelector
                    ref={ref}
                    options={state.giftPrices}
                    onChange={(val) => {
                      if (val) {
                        event('event_site_gift_select-price');
                        setSavedSumObj(val);
                      }
                      if (!isPhotobook(val?.products)) {
                        setValue('isDesignOn', false);
                      }
                      onChange(val);
                    }}
                    value={value}
                    error={errors && errors.sum && errors.sum.message}
                  />
                )}
                name="sum"
                control={control}
                rules={{
                  required: 'Выберите номинал сертификата',
                  min: {
                    value: 100,
                    message: 'Выберите номинал сертификата',
                  },
                }}
              />
              <Controller
                control={control}
                name="isDesignOn"
                defaultValue={false}
                render={({ field: { onChange, value } }) =>
                  isPhotobook(watchSum?.products) ? (
                    <CheckboxField
                      title="Добавить услугу «Соберём фотокнигу за вас»"
                      description={`за ${DEFAULT_DESIGN_PRICE.toLocaleString('ru-RU')} ₽`}
                      onChange={(val) => onChange(val)}
                      checked={value}
                    />
                  ) : null
                }
              />
            </div>
          ) : (
            <Controller
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="Номинал сертификата"
                  name="customSum"
                  error={errors && errors.customSum && errors.customSum.message}
                >
                  <TextField
                    ref={ref}
                    name="customSum"
                    error={errors && errors.customSum && errors.customSum.message}
                    onChange={(val) => {
                      const newVal = Number(`${val}`.replace(/[^\d]/gi, ''));
                      setSavedCustomSum(newVal);
                      onChange(newVal);
                    }}
                    onBlur={() => value && event('event_site_gift_enter-sum')}
                    value={value || ''}
                    type="text"
                    placeholder="от 1500 ₽"
                  />
                </FormField>
              )}
              control={control}
              name="customSum"
              rules={{
                required: 'Введите номинал сертификата',
                min: {
                  value: 1500,
                  message: 'Минимальная сумма сертификата 1500 ₽',
                },
              }}
            />
          )}
        </div>
        <GiftBonusBlock sum={giftSumType === GIFT_SUM_DEFAULT ? totalSum : savedCustomSum} />

        <div className={styles.formBlock}>
          <FormBlockHeading>4. От кого</FormBlockHeading>
          <div className={styles.formField}>
            <Controller
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="Имя отправителя"
                  name="nameFrom"
                  error={errors && errors.nameFrom && errors.nameFrom.message}
                >
                  <TextField
                    ref={ref}
                    name="nameFrom"
                    onBlur={() =>
                      value &&
                      !(errors && errors.nameFrom && errors.nameFrom.message) &&
                      event('event_site_gift_enter-name-from')
                    }
                    error={errors && errors.nameFrom && errors.nameFrom.message}
                    onChange={(val) => onChange(val)}
                    value={value}
                  />
                </FormField>
              )}
              control={control}
              name="nameFrom"
              rules={{
                required: 'Введите имя отправителя',
              }}
            />
          </div>
          <div className={styles.formField}>
            <Controller
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="E-mail отправителя"
                  name="email"
                  description="Отправим подтверждение заказа и оплаты"
                  error={errors && errors.email && errors.email.message}
                >
                  <TextField
                    ref={ref}
                    name="email"
                    onBlur={() =>
                      value &&
                      !(errors && errors.email && errors.email.message) &&
                      event('event_site_gift_enter-mail')
                    }
                    error={errors && errors.email && errors.email.message}
                    onChange={(val) => onChange(val)}
                    value={value}
                    type="email"
                  />
                </FormField>
              )}
              control={control}
              name="email"
              rules={{
                required: 'Введите E-mail отправителя',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Введите E-mail в формате name@domain.com',
                },
              }}
            />
          </div>
          <Controller
            render={({ field: { onChange, value, ref } }) => (
              <FormField
                name="phone"
                label="Телефон отправителя"
                error={errors && errors.phone && errors.phone.message}
              >
                <PhoneInput
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  onBlur={() =>
                    value &&
                    !(errors && errors.phone && errors.phone.message) &&
                    event('event_site_gift_enter-phone')
                  }
                  name="phone"
                  type="text"
                  placeholder="+7 (___) ___-__-__"
                  error={errors && errors.phone && errors.phone.message}
                />
              </FormField>
            )}
            name="phone"
            control={control}
            rules={{
              required: 'Введите телефон отправителя',
              validate: (value) => validatePhoneNumber(value),
            }}
          />
        </div>

        <div className={styles.formBlock}>
          <FormBlockHeading>5. Куда отправить сертификат</FormBlockHeading>
          <Controller
            render={({ field: { onChange, value } }) => (
              <DeliveryTypeField
                onChange={handleDeliveryTypeChange(onChange, value)}
                value={value}
                info={switchInfo}
                customClassName="delivery-type"
              />
            )}
            name="deliveryType"
            control={control}
          />
          {deliveryType === TYPE_RECIPIENT_EMAIL && (
            <Controller
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  name="emailTo"
                  label="E-mail получателя"
                  error={errors && errors.emailTo && errors.emailTo.message}
                >
                  <TextField
                    ref={ref}
                    name="emailTo"
                    error={errors && errors.emailTo && errors.emailTo.message}
                    onChange={(val) => onChange(val)}
                    onBlur={() =>
                      value &&
                      !(errors && errors.emailTo && errors.emailTo.message) &&
                      event('event_site_gift_enter-mail-to')
                    }
                    value={value}
                    type="email"
                  />
                </FormField>
              )}
              control={control}
              name="emailTo"
              rules={{
                required: 'Введите E-mail получателя',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Введите E-mail в формате name@domain.com',
                },
              }}
            />
          )}
        </div>

        <div className={styles.submitGroup}>
          <Button form="giftForm" size="x2Large" className={styles.giftButtonMargin}>
            Оформить сертификат
          </Button>
          <Controller
            control={control}
            name="client_newsletter_agreed"
            defaultValue
            render={({ field: { value, onChange } }) => (
              <CheckboxField
                title="Получать специальные предложения и уведомления об акциях."
                onChange={(val) => onChange(val)}
                value={value}
                checked={value}
              />
            )}
          />
          <Note buttonName="Оформить сертификат" />
        </div>
      </div>
    </form>
  );
}

GiftForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: propTypes.object,
};
GiftForm.defaultProps = {
  values: {},
};

export default GiftForm;
