import React, { useState } from 'react';
import cn from 'classnames';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { DEFAULT_DESIGN_PRICE } from '@consts/designConsts';

import { Button, LoaderButton, Paragraph as P, Heading as H } from '@periodica/ui-kit';
import MiniProductBlock from '@components/Blocks/MiniProductBlock';
import Certificate from '@components/Blocks/CertificatePreview';

import discountPic from '@images/icons/discount.svg';
import receiptPic from '@images/icons/receipt.svg';
import tabsPic from '@images/icons/tabs.svg';
import supportPic from '@images/icons/icon-support.svg';

import { logGiftPurchase } from '@analytics/gtm';
import { orderGift } from '../../../periodicaApi/gift';
import { GiftBonusBlock, getBonusPercent } from './GiftBonusBlock';

// TODO: move styles
import styles from './GiftConfirmation.module.scss';

interface InfoStringBlockArgs {
  image: string;
  description: string;
}

function InfoStringBlock({ image, description }: InfoStringBlockArgs) {
  return (
    <div className={styles.infoBlock}>
      <img src={image} alt="изображение" />
      <P size="large">{description}</P>
    </div>
  );
}

interface DeliveryBlockArgs {
  title: string;
  description: string;
}
function DeliveryBlock({ title, description }: DeliveryBlockArgs) {
  return (
    <div className={styles.deliveryBlock}>
      <P color="secondary">{title}</P>
      <P size="large">{description}</P>
    </div>
  );
}

const getMiniProductBlock = (p: any, idx: number) => {
  if (idx > 1) return null;
  return <MiniProductBlock photo={p.image} title={p.title} subtitle={p.description} key={p.id} />;
};

function GiftConfirmationPage() {
  const location = useLocation();
  const { state } = location;
  const {
    emailTo,
    nameFrom,
    email,
    nameTo,
    pattern,
    phone,
    sum,
    customSum,
    previewUrl,
    isDesignOn,
    // eslint-disable-next-line camelcase
    client_newsletter_agreed: clientNewsletterAgreed,
    deliveryType,
  } = state as any;
  const tempSum = parseInt(sum?.sum || customSum, 10);
  const totalSum = isDesignOn ? tempSum + DEFAULT_DESIGN_PRICE : tempSum;
  const bonusSum = totalSum + Math.round(getBonusPercent(totalSum) * totalSum);

  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!state) {
    navigate('/products/gift');
    return null;
  }

  const returnToGiftScreen = () => {
    navigate('/products/gift/', {
      state: { ...state },
    });
  };

  const isDeliveryToPerson = deliveryType === 1;

  const getHeader = isDeliveryToPerson
    ? 'Это увидит получатель на почте'
    : 'Так будет выглядеть PDF файл';

  const handlePay = async () => {
    try {
      setIsSubmitting(true);
      const order = await orderGift(
        pattern.template_id,
        nameFrom,
        phone,
        email,
        nameTo,
        isDeliveryToPerson ? emailTo : null,
        pattern.text,
        totalSum,
        previewUrl,
        clientNewsletterAgreed
      );
      await logGiftPurchase(order);
      if (order.payment_url) {
        navigate(order.payment_url);
      } else {
        setIsSubmitting(false);
      }
    } catch (err) {
      console.error(err);
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <H className={styles.h1}>Проверьте ваш заказ</H>
      <P size="large" color="secondary" className={styles.s1}>
        {getHeader}
      </P>
      <div className={styles.content}>
        {isDeliveryToPerson ? (
          <div className={cn([styles.contentBlock, styles.giftInfo])}>
            <H className={styles.h1} customTag="p">
              {nameTo}
            </H>
            <P>Вам сертификат в подарок!</P>
            <div className={styles.giftCard}>
              <Certificate
                image={pattern.frontLayout}
                text={pattern.text}
                nameTo={nameTo}
                nameFrom={nameFrom}
                sum={bonusSum}
              />
            </div>
          </div>
        ) : (
          <div className={cn([styles.contentBlock, styles.giftCard])}>
            <Certificate
              image={pattern.frontLayout}
              text={pattern.text}
              nameTo={nameTo}
              nameFrom={nameFrom}
              sum={bonusSum}
            />
          </div>
        )}
        {!!sum && (
          <div className={cn([styles.contentBlock, styles.simpleBlock])}>
            <H variant="h3" customTag="h2">
              На сумму сертификата можно заказать
            </H>
            {sum.products.map(getMiniProductBlock)}
          </div>
        )}
        <div className={cn([styles.contentBlock, styles.simpleBlock])}>
          <H variant="h3" customTag="h2">
            С сертификатом можно
          </H>
          <InfoStringBlock image={supportPic} description="Заказать любые продукты Периодики" />
          <InfoStringBlock image={discountPic} description="Оплатить заказ целиком или его часть" />
          <InfoStringBlock
            image={receiptPic}
            description="Использовать несколько раз до исчерпания баланса"
          />
          {isDesignOn && (
            <InfoStringBlock
              image={tabsPic}
              description="Оплатить услугу «Соберем фотокнигу за вас»"
            />
          )}
        </div>
        <div className={cn([styles.contentBlock, styles.simpleBlock])}>
          <H variant="h3" customTag="h2">
            Информация для доставки
          </H>
          {isDeliveryToPerson ? (
            <DeliveryBlock title="E-mail получателя" description={emailTo} />
          ) : (
            <DeliveryBlock title="E-mail отправителя" description={email} />
          )}
          <DeliveryBlock title="Телефон отправителя" description={phone} />
        </div>
        <div className={cn([styles.contentBlock, styles.buttonWrapper])}>
          <Button type="button" onClick={returnToGiftScreen} variant="brandSecondary" size="xLarge">
            Изменить дизайн и данные
          </Button>
        </div>
        <GiftBonusBlock sum={totalSum} toPay />
        <LoaderButton
          type="button"
          size="xLarge"
          onClick={handlePay}
          className={styles.submitButton}
          loading={isSubmitting}
        >
          К оплате
        </LoaderButton>
      </div>
    </div>
  );
}

export default GiftConfirmationPage;
