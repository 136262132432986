import React from 'react';
import { Router } from '@reach/router';

import Layout from '@components/Layouts/Layout';
import SEO from '@components/Layouts/SEO';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import GiftConfirmationPage from '@components/Forms/Gift/GiftConfirmationPage';
import GiftPage from '@components/Forms/Gift/GiftPage';

export function Head() {
  return (
    <>
      <SEO
        title="Сертификат на фотокнигу: изготовление и печать фотокниг в подарок"
        description="Закажите универсальный подарок для коллег, друзей и родных: электронный сертификат на фотокнигу! Порадуйте близких фотоальбомом на заказ из собственных фотографий!"
      />
      <YandexTableau />
    </>
  );
}

function Gift() {
  // fix bug with nested pages caused by rehydration
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  return !hasMounted ? null : (
    <Layout>
      <Router basepath="/products/gift">
        <GiftPage path="/" />
        <GiftConfirmationPage path="/confirmation" />
      </Router>
    </Layout>
  );
}

export default Gift;
