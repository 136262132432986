import { findCountable } from './countable';

const getProductPageCount = (value, productType = 'book') => {
  const countableProductType = productType === 'prints' ? 'items' : 'pages';

  return findCountable({ value, type: countableProductType });
};

const getProductsForPrices = (products, price) => {
  const suitableProducts = products.filter((product) => product.price === price.sum);

  return { ...price, products: suitableProducts };
};

export const getCertificatePrices = (products) => {
  const prices = [];
  // get available product prices

  const normalizedProducts = products.map((product) => {
    const {
      id,
      productType,
      title,
      image,
      options: { pageOptions },
    } = product;

    const price = !!pageOptions && pageOptions[0].price;

    return {
      id,
      productType,
      title,
      price,
      image,
      hasSubProducts: !!pageOptions && pageOptions.length > 1,
      pageCount: pageOptions.length ? getProductPageCount(pageOptions[0].value, productType) : 0,
      pageOptions,
    };
  });

  const productsWithoutSubs = [];
  const productsWithSubs = [];

  // Sort the products
  normalizedProducts.forEach((product) => {
    if (product.hasSubProducts) {
      productsWithSubs.push(product);
    } else {
      productsWithoutSubs.push(product);
    }
  });

  // Create all the product variations based on pageOptions
  const subProducts = [];
  productsWithSubs.forEach((product) => {
    product.pageOptions.forEach((pageOption) => {
      const subProductPrice = pageOption.price;
      const subProductPageCount = pageOption.value;

      const newProduct = {
        ...product,
        id: `${product.id}__SUB`,
        price: subProductPrice,
        pageCount: getProductPageCount(subProductPageCount, product.productType),
      };

      subProducts.push(newProduct);
    });
  });

  // Merge the resulting arrays
  const allProducts = [...productsWithoutSubs, ...subProducts];

  allProducts.forEach((product) => {
    prices.push(product.price);
  });
  // get unique prices and sort asc
  let result = prices
    .filter((p, idx, arr) => arr.indexOf(p) === idx)
    .sort((prev, next) => prev - next);

  // available products for each price
  result = result.map((price) => ({
    id: `${price}__PRICE`,
    sum: price,
    products: [],
  }));

  return result.map((price) => getProductsForPrices(allProducts, price));
};
